import moment, { Moment } from 'moment-timezone';
import { useIntl } from 'react-intl';

import { Checkbox, TextField } from '@cimpress/react-components';
import { DatePicker } from '@cimpress/react-components';

import { SelectField } from '../types';
import DeliveryGroupSelector from './DeliveryGroupSelector';
import DeliveryOptionSelector from './DeliveryOptionSelector';
import MerchantSelector from './MerchantSelector';
import messages from './messages';
import { MerchantState } from './state';

type MerchantInputPanelProps = {
  merchantState: MerchantState;
  setMerchantState: (v: MerchantState) => void;
  disabled: boolean;
  timezone: string | undefined;
};

const MerchantInputPanel = ({ merchantState, setMerchantState, disabled, timezone }: MerchantInputPanelProps) => {
  const { formatMessage } = useIntl();

  const onChangeMerchant = (selection: SelectField) => {
    setMerchantState({ ...merchantState, merchantId: selection.value });
  };

  const onChangeMinutesToOrderSubmittal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMerchantState({ ...merchantState, minutesToOrderSubmittal: parseInt(e.target.value) });
  };

  const onChangeRequestDate = (date: string | Moment) => {
    let asMoment: Moment | undefined;
    if (typeof date === 'string' && date !== '') {
      asMoment = moment(date);
    } else if (date) {
      asMoment = date;
    } else {
      asMoment = undefined;
    }
    setMerchantState({ ...merchantState, requestDate: asMoment });
  };

  const onChangeIgnoreInTransitInventory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState: MerchantState = {
      ...merchantState,
      ignoreInTransitInventory: !merchantState.ignoreInTransitInventory,
    };
    setMerchantState(newState);
  };

  const onChangeEcommerceDeliveryGroup = (selectedGroup: { label: string; value: string } | null) => {
    const newState: MerchantState = {
      ...merchantState,
      ecommerceDeliveryGroup: selectedGroup?.value,
      ecommerceDeliveryOption: '',
    };
    setMerchantState(newState);
  };

  const onChangeEcommerceDeliveryOption = (selectedOption: { label: string; value: string } | null) => {
    const newState: MerchantState = {
      ...merchantState,
      ecommerceDeliveryOption: selectedOption?.value,
    };
    setMerchantState(newState);
  };

  return (
    <div>
      <h3>{formatMessage(messages.selectAMerchant)}</h3>
      <MerchantSelector value={merchantState.merchantId} onChange={onChangeMerchant} required isDisabled={disabled} />

      <h3>{formatMessage(messages.selectAnEdg)}</h3>
      <DeliveryGroupSelector
        value={merchantState.ecommerceDeliveryGroup}
        onChange={onChangeEcommerceDeliveryGroup}
        isClearable={true}
      />
      {merchantState.ecommerceDeliveryGroup && (
        <>
          <h3>{formatMessage(messages.selectAnEdo)}</h3>
          <DeliveryOptionSelector
            groupId={merchantState.ecommerceDeliveryGroup}
            value={merchantState.ecommerceDeliveryOption}
            onChange={onChangeEcommerceDeliveryOption}
            isClearable={true}
          />
        </>
      )}

      <h3>{formatMessage(messages.minutesToOrderSubmittal)}</h3>
      <TextField
        type="number"
        min="0"
        value={merchantState.minutesToOrderSubmittal}
        onChange={onChangeMinutesToOrderSubmittal}
        disabled={disabled}
      />

      <h3>{formatMessage(messages.requestDate)}</h3>
      <DatePicker
        label={formatMessage(messages.selectADate)}
        value={timezone ? merchantState.requestDate?.tz(timezone) : merchantState.requestDate}
        onChange={onChangeRequestDate}
        isClearable={true}
        disabled={disabled}
      />

      <Checkbox
        label={formatMessage(messages.ignoreInTransitInventory)}
        checked={merchantState.ignoreInTransitInventory}
        onChange={onChangeIgnoreInTransitInventory}
        disabled={disabled}
      />
    </div>
  );
};

export default MerchantInputPanel;
