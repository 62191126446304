/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';

import { ConfigurationCreation, createConfigurationUrl, VariableAttribute } from './configuration';
import fetchWithAuth from './fetchWithAuth';

export type MCPProduct = {
  productId: string;
  version: string;
  selections: any;
};

export type BesResponse = {
  rootConfiguration: MCPProduct;
};

export const convertToMCPProduct = async ({
  productId,
  version,
  selections,
}: {
  productId: string;
  version: string;
  selections: any[];
}): Promise<string> => {
  const formattedSelections: any = {};
  selections.forEach(selection => {
    formattedSelections[`selections[${selection.key}]`] = selection.resolvedValue;
  });
  const params = _.merge({ productId, version }, formattedSelections);
  const response = await fetchWithAuth<BesResponse>({
    giveSimpleResponse: true,
    method: 'GET',
    endpointUrl: process.env.REACT_APP_BES_URL as string,
    route: '/api/v2/bindings:resolverootconfiguration',
    params,
  });
  if (response.rootConfiguration) {
    const mcpProduct = response.rootConfiguration;
    const variables: VariableAttribute[] = Object.keys(mcpProduct.selections).map(key => ({
      attributeKey: key,
      attributeValue: mcpProduct.selections[key],
    }));
    const createConfigBody: ConfigurationCreation = {
      mcpSku: mcpProduct.productId,
      variables,
      productVersion: mcpProduct.version,
    };
    return await createConfigurationUrl(createConfigBody);
  }
  throw new Error('PRD conversion was not successful');
};
