import { css } from '@emotion/css';
import React, { useMemo, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Link, useLocation } from 'react-router-dom';

import Header from '@cimpress-technology/react-platform-header';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';
import { GlobalStyles } from '@cimpress/react-components';

import auth from '../auth';
import messages from './messages';
import Settings from './userSettings/Settings';

const iconCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Shell = ({ children }: { children: ReactNode }) => {
  const { formatMessage } = useIntl();
  const settings = <Settings />;

  const queryCache = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
    [],
  );

  const helpLink = (
    <a
      href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/15436415010/Delivery+Calculator"
      target="_blank"
      rel="noreferrer"
    >
      <div className={iconCss}>
        {'Support Help'}
        <IconBookOpen />
      </div>
    </a>
  );

  const location = useLocation();
  const title =
    location.pathname === '/' ? formatMessage(messages.deliveryCalculator) : formatMessage(messages.optionCalculator);

  return (
    <>
      <GlobalStyles />
      <QueryClientProvider client={queryCache}>
        <Header
          responsive
          authToken={auth.getAccessToken()}
          appTitle={title}
          appLinks={[
            {
              id: 'itemEvaluations',
              content: <Link to="/">Dates</Link>,
              isActive: location.pathname === '/',
            },
            {
              id: 'optionEvaluations',
              content: <Link to="/optionEvaluations">Options</Link>,
              isActive: location.pathname === '/optionEvaluations',
            },
            {
              id: 'support',
              content: helpLink,
            },
            {
              id: 'settings',
              content: settings,
            },
          ]}
          accessToken={auth.getAccessToken()}
          profile={auth.getProfile()}
          isLoggedIn={auth.isLoggedIn()}
          onLogInClicked={() => {
            auth.login({ nextUri: window.location.pathname, forceLogin: true });
          }}
          onLogOutClicked={() => {
            auth.logout(window.location.pathname + window.location.search);
          }}
        />
        <div style={{ minHeight: 'calc(100vh - 225px)' }}>{children}</div>
      </QueryClientProvider>
    </>
  );
};

export default Shell;
