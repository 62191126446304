import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import { useCarrierServices } from '../../hooks/useCarrierServices';
import messages from './messages';

const CarrierServicesSelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const { isLoading, data: carrierServices } = useCarrierServices();

  if (isLoading) {
    return <Select label={formatMessage(messages.loading)} isDisabled />;
  }

  const options =
    carrierServices?.map(carrierService => ({ label: carrierService.name, value: carrierService.key })) ?? [];

  return (
    <Select
      {...props}
      label={formatMessage(messages.carrierService)}
      options={options}
      value={options.filter(option => props.value?.includes(option.value) ?? false)}
      isClearable
      isMulti
    />
  );
};

export default CarrierServicesSelector;
