import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import messages from './messages';

const FulfillmentCapabilitiesSelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const FULFILLMENT_CAPABILITIES = [
    {
      value: 'cashOnDelivery',
      label: formatMessage(messages.cashOnDelivery),
    },
    {
      value: 'doNotProduce',
      label: formatMessage(messages.doNotProduce),
    },
    {
      value: 'doNotShip',
      label: formatMessage(messages.doNotShip),
    },
    {
      value: 'followsPlatformShipmentPlans',
      label: formatMessage(messages.followsPlatformShipmentPlans),
    },
    {
      value: 'liveOrders',
      label: formatMessage(messages.liveOrders),
    },
    {
      value: 'moneyBackGuaranteedDelivery',
      label: formatMessage(messages.moneyBackGuaranteedDelivery),
    },
    {
      value: 'multipleAddressItems',
      label: formatMessage(messages.multipleAddressItems),
    },
    {
      value: 'pickupPoints',
      label: formatMessage(messages.pickupPoints),
    },
    {
      value: 'sampleShipping',
      label: formatMessage(messages.sampleShipping),
    },
    {
      value: 'whiteLabelling',
      label: formatMessage(messages.whiteLabelling),
    },
  ];

  return (
    <Select
      {...props}
      label={formatMessage(messages.fulfillmentCapability)}
      options={FULFILLMENT_CAPABILITIES}
      value={FULFILLMENT_CAPABILITIES.filter(option => props.value?.includes(option.value) ?? false)}
      isClearable
      isMulti
    />
  );
};

export default FulfillmentCapabilitiesSelector;
