import currencyCodes from 'currency-codes';
import React from 'react';
import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import messages from '../messages';

const CurrencySelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const options = currencyCodes.codes().map(cc => ({ label: cc, value: cc }));
  const menuPortalTarget = document.getElementById('settings-modal');

  return (
    <Select
      {...props}
      name="currency"
      placeholder={formatMessage(messages.currency)}
      menuPortalTarget={menuPortalTarget}
      helpText={formatMessage(messages.currencyHelpText)}
      options={options}
      value={props.selection}
      isClearable
    />
  );
};

export default CurrencySelector;
