import { uniqueId } from 'lodash';
import { Moment } from 'moment';

export type MerchantState = {
  readonly merchantId: string;
  readonly minutesToOrderSubmittal: number | undefined;
  readonly requestDate: Moment | undefined;
  readonly ignoreInTransitInventory: boolean;
  readonly ecommerceDeliveryGroup: string | undefined;
  readonly ecommerceDeliveryOption: string | undefined;
  readonly edoUrl?: string;
};

export const defaultMerchantState: MerchantState = {
  merchantId: '',
  minutesToOrderSubmittal: 0,
  requestDate: undefined,
  ignoreInTransitInventory: false,
  ecommerceDeliveryGroup: '',
  ecommerceDeliveryOption: '',
  edoUrl: '',
};

export type ProductState = {
  readonly mcpSku: string;
  readonly productVersion: string;
  readonly productConfigurationUrl: string;
};

export type AddressType = 'residential' | 'commercial' | 'pickupPoint' | undefined;

export const defaultProductState: ProductState = {
  mcpSku: '',
  productVersion: '',
  productConfigurationUrl: '',
};

export type LocationState = {
  readonly postalCode: string;
  readonly deliveryCountry: string;
  readonly poBox: boolean;
  readonly addressType?: AddressType;
  readonly pickupPointUrl: string;
};

export const defaultLocationState: LocationState = {
  postalCode: '',
  deliveryCountry: '',
  poBox: false,
  addressType: undefined,
  pickupPointUrl: '',
};

export type ItemFulfillmentCapability = 'cashOnDelivery' | 'whiteLabelling' | 'moneyBackGuaranteedDelivery';

export type IDPFulfillmentCapability =
  | 'cashOnDelivery'
  | 'pickupPoints'
  | 'sampleShipping'
  | 'multipleAddressItems'
  | 'liveOrders'
  | 'doNotShip'
  | 'whiteLabelling'
  | 'followsPlatformShipmentPlans'
  | 'doNotProduce'
  | 'moneyBackGuaranteedDelivery';

export type DeliveryConstraintsState = {
  readonly fulfillmentCapabilities: IDPFulfillmentCapability[];
  readonly carrierServices: string[];
  readonly carrierServiceCapabilities: string[];
};

export const defaultDeliveryConstraintsState: DeliveryConstraintsState = {
  fulfillmentCapabilities: [],
  carrierServices: [],
  carrierServiceCapabilities: [],
};

export type DeliveryGroupState = {
  readonly deliveryGroupId: string;
  readonly requestDate: Moment | undefined;
};

export const defaultDeliveryGroupState: DeliveryGroupState = {
  deliveryGroupId: '',
  requestDate: undefined,
};

export type ItemState = ProductState & {
  readonly key: string;
  readonly skuOrProductConfiguration: string;
};

export type ItemsState = { readonly items: ItemState[] };

export const defaultItem: ItemState = {
  key: uniqueId(),
  mcpSku: '',
  productVersion: '',
  productConfigurationUrl: '',
  skuOrProductConfiguration: '',
};

export const defaultItemsState: ItemsState = {
  items: [defaultItem],
};

export const MERCHANT_TAB_ID = 'MERCHANT_TAB';
export const PRODUCT_TAB_ID = 'PRODUCT_TAB';
export const LOCATION_TAB_ID = 'LOCATION_TAB';
export const DELIVERY_CONSTRAINTS_TAB_ID = 'DELIVERY_CONSTRAINTS_TAB';
export const DELIVERY_GROUP_TAB_ID = 'DELIVERY_GROUP_TAB';
export const ITEMS_TAB_ID = 'ITEMS_TAB';

export type NavigationTabId =
  | typeof MERCHANT_TAB_ID
  | typeof PRODUCT_TAB_ID
  | typeof LOCATION_TAB_ID
  | typeof DELIVERY_CONSTRAINTS_TAB_ID;

export type CartNavigationTabId = typeof DELIVERY_GROUP_TAB_ID | typeof ITEMS_TAB_ID | typeof LOCATION_TAB_ID;
