import { defineMessages } from 'react-intl';

export default defineMessages({
  carrierServices: {
    id: 'inputPanels.carrierServices',
    defaultMessage: 'Carrier services (optional)',
  },
  carrierCapabilties: {
    id: 'inputPanels.carrierCapabilties',
    defaultMessage: 'Carrier capabilities (optional)',
  },
  deliveryCountry: {
    id: 'inputPanels.deliveryCountry',
    defaultMessage: 'Delivery Country',
  },
  fulfillmentCapabilities: {
    id: 'inputPanels.fulfillmentCapabilities',
    defaultMessage: 'Fulfillment capabilities (optional)',
  },
  fulfillmentCapability: {
    id: 'inputPanels.fulfillmentCapability',
    defaultMessage: 'Fulfillment capability',
  },
  postalCode: {
    id: 'inputPanels.postalCode',
    defaultMessage: 'Postal Code',
  },
  postalCodeErrorMessage: {
    id: 'inputPanels.postalCodeErrorMessage',
    defaultMessage: 'Invalid Postal Code for {country}',
  },
  locationOptions: {
    id: 'inputPanels.locationOptions',
    defaultMessage: 'Location options',
  },
  poBox: {
    id: 'inputPanels.poBox',
    defaultMessage: 'PO Box',
  },
  pickupPointUrl: {
    id: 'inputPanels.pickupPointUrl',
    defaultMessage: 'Pickup Point URL',
  },
  residentialAddress: {
    id: 'inputPanels.residentialAddress',
    defaultMessage: 'Residential address',
  },
  selectAMerchant: {
    id: 'inputPanels.selectAMerchant',
    defaultMessage: 'Select a merchant',
  },
  selectAnEdg: {
    id: 'inputPanels.selectAnEdg',
    defaultMessage: 'Select an ecommerce delivery group',
  },
  selectAnEdo: {
    id: 'inputPanels.selectAnEdo',
    defaultMessage: 'Select an ecommerce delivery option',
  },
  selectADeliveryGroup: {
    id: 'inputPanels.selectADeliveryGroup',
    defaultMessage: 'Select a delivery group',
  },
  minutesToOrderSubmittal: {
    id: 'inputPanels.minutesToOrderSubmittal',
    defaultMessage: 'Minutes to order submittal (optional)',
  },
  requestDate: {
    id: 'inputPanels.requestDate',
    defaultMessage: 'Request date (optional)',
  },
  selectADate: {
    id: 'inputPanels.selectADate',
    defaultMessage: 'Select a date',
  },
  enterAProductSkuOrProductConfigurationUrl: {
    id: 'inputPanels.enterAProductSkuOrProductConfigurationUrl',
    defaultMessage: 'Enter a product ID or product configuration URL',
  },
  loading: {
    id: 'inputPanels.loading',
    defaultMessage: 'Loading...',
  },
  merchant: {
    id: 'inputPanels.merchant',
    defaultMessage: 'Merchant',
  },
  deliveryGroup: {
    id: 'inputPanels.deliveryGroup',
    defaultMessage: 'Delivery Group',
  },
  deliveryOption: {
    id: 'inputPanels.deliveryOption',
    defaultMessage: 'Delivery Option',
  },
  carrierService: {
    id: 'inputPanels.carrierService',
    defaultMessage: 'Carrier service',
  },
  carrierCapability: {
    id: 'inputPanels.carrierCapability',
    defaultMessage: 'Carrier capability',
  },
  country: {
    id: 'inputPanels.country',
    defaultMessage: 'Country',
  },
  productSkuOrProductConfiguration: {
    id: 'inputPanels.productSkuOrProductConfiguration',
    defaultMessage: 'Product ID or Product Configuration URL',
  },
  productVersion: {
    id: 'inputPanels.productVersion',
    defaultMessage: 'Product Version',
  },
  addressType: {
    id: 'inputPanels.addressType',
    defaultMessage: 'Address Type',
  },
  residential: {
    id: 'inputPanels.residential',
    defaultMessage: 'Residential',
  },
  commercial: {
    id: 'inputPanels.commercial',
    defaultMessage: 'Commercial',
  },
  ignoreInTransitInventory: {
    id: 'inputPanels.ignoreInTransitInventory',
    defaultMessage: 'Ignore In-Transit Inventory',
  },
  cashOnDelivery: {
    id: 'inputPanels.cashOnDelivery',
    defaultMessage: 'Cash on delivery',
  },
  doNotProduce: {
    id: 'inputPanels.doNotProduce',
    defaultMessage: 'Do not produce',
  },
  doNotShip: {
    id: 'inputPanels.doNotShip',
    defaultMessage: 'Do not ship',
  },
  followsPlatformShipmentPlans: {
    id: 'inputPanels.followsPlatformShipmentPlans',
    defaultMessage: 'Follows platform shipment plans',
  },
  liveOrders: {
    id: 'inputPanels.liveOrders',
    defaultMessage: 'Live orders',
  },
  moneyBackGuaranteedDelivery: {
    id: 'inputPanels.moneyBackGuaranteedDelivery',
    defaultMessage: 'Money back guaranteed delivery',
  },
  multipleAddressItems: {
    id: 'inputPanels.multipleAddressItems',
    defaultMessage: 'Multiple address items',
  },
  pickupPoints: {
    id: 'inputPanels.pickupPoints',
    defaultMessage: 'Pickup points',
  },
  sampleShipping: {
    id: 'inputPanels.sampleShipping',
    defaultMessage: 'Sample shipping',
  },
  whiteLabelling: {
    id: 'inputPanels.whiteLabelling',
    defaultMessage: 'White labelling',
  },
});
