import { css } from '@emotion/css';
import { Moment } from 'moment-timezone';

import { colors } from '@cimpress/react-components';

type DeliveryPossibilitiesHeaderProps = {
  earliestDeliverableDate: Moment | undefined;
  estimateExpirationDate: Moment;
  timezone: string | undefined;
};

const headerCss = css`
  width: 100%;
`;

const deliverableDateHeaderCss = css`
  background: ${colors.info.base};
  color: ${colors.white};
  margin-bottom: 0px;
`;

const deliverableDateCss = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom 0px;
  padding: 10px 0px 10px;

  > h4 {
    margin-top: 9px;
    margin-bottom: 5px;
    line-height: 18.7px;
  }
`;

const expirationDateHeaderCss = css`
  background: #ffebdb;
`;

const expirationDateCss = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
`;

const DeliveryPossibilitiesHeader = ({
  earliestDeliverableDate,
  estimateExpirationDate,
  timezone,
}: DeliveryPossibilitiesHeaderProps) => {
  return (
    <div className={headerCss}>
      <div className={deliverableDateHeaderCss}>
        <div className={deliverableDateCss}>
          <h4>Earliest Deliverable Date:</h4>
          {earliestDeliverableDate?.format('dddd, MMMM Do, YYYY') ?? 'Undeliverable'}
        </div>
      </div>
      <div className={expirationDateHeaderCss}>
        <p className={expirationDateCss}>
          This estimate expires on{' '}
          {timezone
            ? estimateExpirationDate.tz(timezone).format('dddd, MMMM Do YYYY, h:mm:ss a (z)')
            : estimateExpirationDate.format('dddd, MMMM Do YYYY, h:mm:ss a')}
        </p>
      </div>
    </div>
  );
};

export default DeliveryPossibilitiesHeader;
