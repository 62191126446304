import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import { useCarrierCapabilities } from '../../hooks/useCarrierCapabilities';
import messages from './messages';

const CarrierCapabilitiesSelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const { isLoading, data: carrierCapabilities } = useCarrierCapabilities();

  if (isLoading) {
    return <Select {...props} label={formatMessage(messages.loading)} isDisabled />;
  }

  const options =
    carrierCapabilities?.map(carrierCapabilities => ({
      label: carrierCapabilities.name,
      value: carrierCapabilities.key,
    })) ?? [];

  const selectedOptions =
    props.value?.map((value: string) => options.find(option => option.value === value) ?? { label: value, value }) ??
    [];

  return (
    <Select
      {...props}
      label={formatMessage(messages.carrierCapability)}
      options={options}
      value={selectedOptions}
      isClearable
      isMulti
    />
  );
};

export default CarrierCapabilitiesSelector;
