import { useState } from 'react';
import { useIntl } from 'react-intl';

import { colors, Button, TextField } from '@cimpress/react-components';

import { isCustomQuotesProduct } from '../../helpers/customquotes';
import messages from './messages';
import { ProductState } from './state';

type ProductInputTextFieldProps = {
  skuOrProductConfigurationText: string;
  setSkuOrProductConfigurationText: (v: string) => void;
  productVersion: string;
  setProductVersion: (v: string) => void;
  productState: ProductState;
  setProductState: (v: ProductState) => void;
  disabled: boolean;
};

export const ProductInputTextField = ({
  skuOrProductConfigurationText,
  setSkuOrProductConfigurationText,
  productVersion,
  setProductVersion,
  productState,
  setProductState,
  disabled,
}: ProductInputTextFieldProps) => {
  const [urlHasError, setUrlHasError] = useState(false);

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    setUrlHasError(false);

    // regex stolen from https://stackoverflow.com/a/17773849
    const urlRegex = new RegExp(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    );
    const isUrl = skuOrProductConfigurationText.match(urlRegex);

    if (isUrl) {
      const hostname = (() => {
        try {
          return new URL(skuOrProductConfigurationText).hostname;
        } catch {
          return '';
        }
      })();

      if (hostname.endsWith('configuration.products.cimpress.io')) {
        const newProductState = { ...productState, productConfigurationUrl: skuOrProductConfigurationText, mcpSku: '' };
        setProductState(newProductState);
      } else {
        setUrlHasError(true);
        const newProductState = { ...productState, productConfigurationUrl: '', mcpSku: '' };
        setProductState(newProductState);
      }
    } else {
      const newProductState = { ...productState, productConfigurationUrl: '', mcpSku: skuOrProductConfigurationText };
      setProductState(newProductState);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSkuOrProductConfigurationText(e.target.value);
  };

  const onVersionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductVersion(e.target.value);
  };

  const { formatMessage } = useIntl();
  return (
    <form onSubmit={onSubmit}>
      <TextField
        type="text"
        label={formatMessage(messages.productSkuOrProductConfiguration)}
        value={skuOrProductConfigurationText}
        onChange={onChange}
        rightAddon={
          <Button variant="default" disabled={!skuOrProductConfigurationText || disabled}>
            Search
          </Button>
        }
        required
        status={urlHasError ? 'error' : undefined}
        inputStyle={{
          borderColor: isCustomQuotesProduct({
            sku: skuOrProductConfigurationText,
          })
            ? 'red'
            : undefined,
        }}
      />
      {urlHasError ? (
        <p style={{ color: colors.danger.base }}>URL must be a valid product configuration.</p>
      ) : undefined}
      <TextField
        type="search"
        label={formatMessage(messages.productVersion)}
        autoFocus={true}
        value={productVersion}
        onChange={onVersionChange}
        disabled={disabled}
      />
    </form>
  );
};
