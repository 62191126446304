import bPromise from 'bluebird';
import { pickBy, toLower, isNil } from 'lodash';
import { Moment } from 'moment';
import { stringify } from 'querystring';

import { Caveat } from '@cimpress-technology/caveats/dist/types';

import { ItemState } from '../components/inputPanels/state';
import { HydratedCartEvaluation, getHydratedCartEvaluation } from '../helpers/edohydration';
import { VariableAttribute } from './configuration';
import fetchWithAuth from './fetchWithAuth';
import { TracingRecord } from './itemDeliveryPossibilities';

const endpointUrl = process.env.REACT_APP_EDO_SERVICE_URL as string;

export type DeliveryOptionItem = {
  key: string;
  dates: string[];
  fallback: boolean;
  expiryDate: string;
  idpTracingRecord?: TracingRecord;
  _links: {
    self: {
      href: string;
    };
  };
};

export type DeliveryOption = {
  id: string;
  date?: string;
  name: string;
  tags: string[];
  items: DeliveryOptionItem[];
  displayNames?: {
    name: string;
    locale: string;
    description: string;
    merchandizingText: string[];
  };
  _links: {
    self: {
      href: string;
    };
  };
  invalidItems: InvalidItem[];
};

export type InvalidItem = {
  key: string;
  idpTracingRecord?: TracingRecord;
  _embedded: {
    caveats: { warnings: Caveat[] };
    earliestDeliverableDate: string;
    earliestDeliverableDateEdoId: string;
  };
};

export type CartEvaluationItem = {
  key: string;
  deliverableQuantity: number;
  productConfigurationUrl?: string;
  sku?: string;
  attributes?: VariableAttribute[];
  productVersion?: string;
  name: string;
};

export type CartEvaluation = {
  items: CartEvaluationItem[];
  expiryDate: string;
  merchantId: string;
  country?: string;
  postalCode?: string;
  isPOBox?: boolean;
  pickupPointUrl?: string;
  deliveryOptions: DeliveryOption[];
  idpResponses: {
    key: string;
    name: string;
    _links: { deliveryDateCollection: { href: string }; ecommerceDeliveryOption: { href: string } };
  }[];
  createdAt: string;
  requestDateTime?: string;
  _links: { self: { href: string } };
  _embedded: {
    caveats?: {
      errors: Caveat[];
      warnings: Caveat[];
    };
  };
};

export type CreateEvaluationRequest = {
  ecommerceDeliveryGroupId: string;
  items: ItemState[];
  country?: string;
  postalCode?: string;
  pickupPointUrl?: string;
  isPOBox?: boolean;
  requestDateTime?: Moment;
};

type Attribute = {
  class: string;
  attributeKey: string;
  attributeValue: string;
};

type ProductConfiguration = {
  mcpSku: string;
  attributes: Attribute[];
};

export const getCartEvaluation = async (url: string): Promise<CartEvaluation> => {
  return await fetchWithAuth({
    endpointUrl: url,
    method: 'GET',
  });
};

export type EcommerceDeliveryOption = {
  id: string;
  minDays?: number;
  maxDays?: number;
  minOffset?: number;
  maxOffset?: number;
};

export const getEcommerceDeliveryOption = async (url: string): Promise<EcommerceDeliveryOption> => {
  return await fetchWithAuth({
    endpointUrl: url,
    method: 'GET',
  });
};

export type DeliveryGroupCollection = {
  _links: {
    self: {
      href: string;
    };
    first: {
      href: string;
    };
    next?: {
      href: string;
    };
    prev?: {
      href: string;
    };
  };
  offset: number;
  count: number;
  total: number;
  _embedded: {
    ecommerceDeliveryGroups: DeliveryGroupResponse[];
  };
};

export type DeliveryGroupResponse = {
  id: string;
  name?: string;
};

export type DeliveryOptionCollection = {
  _links: {
    self: {
      href: string;
    };
    first: {
      href: string;
    };
    next?: {
      href: string;
    };
    prev?: {
      href: string;
    };
  };
  offset: number;
  count: number;
  total: number;
  _embedded: {
    ecommerceDeliveryOptions: DeliveryOptionResponse[];
  };
};

export type DeliveryOptionResponse = {
  id: string;
  name?: string;
};

export const getDeliveryGroups = async (): Promise<DeliveryGroupResponse[]> => {
  const queryString = {
    offset: 0,
    limit: 200,
  };

  const deliveryGroups: DeliveryGroupResponse[] = [];
  let next: string | undefined;

  do {
    const response: DeliveryGroupCollection = next
      ? await fetchWithAuth({ endpointUrl: next })
      : await fetchWithAuth({
          endpointUrl,
          method: 'GET',
          route: `v1/ecommerceDeliveryGroups?sort=createdAt:desc&${stringify(queryString)}`,
        });

    deliveryGroups.push(...response._embedded.ecommerceDeliveryGroups);
    next = response._links?.next?.href;
  } while (next);

  return deliveryGroups;
};

export const getDeliveryOptionsByGroupId = async ({
  groupId,
}: {
  groupId: string;
}): Promise<DeliveryOptionResponse[]> => {
  const queryString = {
    offset: 0,
    limit: 200,
  };

  const deliveryOptions: DeliveryOptionResponse[] = [];
  let next: string | undefined;

  do {
    const response: DeliveryOptionCollection = next
      ? await fetchWithAuth({ endpointUrl: next })
      : await fetchWithAuth({
          endpointUrl,
          method: 'GET',
          route: `v1/ecommerceDeliveryGroups/${groupId}/ecommerceDeliveryOptions?sort=createdAt:desc&${stringify(
            queryString,
          )}`,
        });

    deliveryOptions.push(...response._embedded.ecommerceDeliveryOptions);
    next = response._links?.next?.href;
  } while (next);

  return deliveryOptions;
};

export const createCartEvaluation = async (request: CreateEvaluationRequest): Promise<HydratedCartEvaluation> => {
  const items = await bPromise.map(request.items, async item => {
    const productConfiguration = await fetchWithAuth<ProductConfiguration>({
      endpointUrl: item.productConfigurationUrl,
    });
    const quantityAttribute = productConfiguration?.attributes.find(
      attribute => attribute.class === 'order' && toLower(attribute.attributeKey) === 'quantity',
    );
    const deliverableQuantity = Number(quantityAttribute?.attributeValue ?? 0);

    return {
      key: item.key.toString(),
      productConfigurationUrl: item.productConfigurationUrl,
      deliverableQuantity,
    };
  });

  const body = pickBy(
    {
      items,
      country: request.country,
      postalCode: request.postalCode,
      pickupPointUrl: request.pickupPointUrl,
      isPOBox: request.isPOBox,
      requestDateTime: request.requestDateTime?.format(),
      storeTracingRecord: true,
      showInventory: true,
    },
    b => !isNil(b),
  );

  const response = await fetchWithAuth<CartEvaluation>({
    endpointUrl: process.env.REACT_APP_EDO_SERVICE_URL as string,
    route: `/v1/ecommerceDeliveryGroups/${request.ecommerceDeliveryGroupId}/cartEvaluations`,
    method: 'POST',
    body,
  });

  return await getHydratedCartEvaluation(response._links.self.href);
};
