import moment, { Moment } from 'moment';

export const encodeValue = (value: string | Moment | Array<string>): string => {
  let normalizedValue: string = value as string;
  if (moment.isMoment(value)) {
    normalizedValue = (value as Moment).format('X');
  }
  if (Array.isArray(value)) {
    normalizedValue = JSON.stringify(value);
  }
  return encodeURIComponent(normalizedValue);
};

export const decodeValue = (value: string | null, isDate = false): string | Moment | undefined => {
  if (!value) {
    return;
  }
  let decodedValue = value;
  try {
    decodedValue = decodeURIComponent(value);
  } catch (e) {
    return undefined;
  }

  // Special handling for arrays
  if (decodedValue[0] === '[') {
    return JSON.parse(decodedValue);
  }

  if (!isDate) {
    return decodedValue;
  }
  // parse as Unix timestamp
  const date = moment(decodedValue, 'X');
  return date.isValid() ? date : decodedValue;
};

export const getIdsFromEdoUrl = (edoUrl: string | undefined) => {
  if (!edoUrl) {
    return;
  }

  const regex = /ecommerceDeliveryGroups\/([0-9a-fA-F-]+)\/ecommerceDeliveryOptions\/([0-9a-fA-F-]+)/;
  const matches = edoUrl.match(regex);

  if (!matches || matches.length === 0) {
    return;
  }

  return {
    ecommerceDeliveryGroupId: matches[1],
    ecommerceDeliveryOptionId: matches[2],
  };
};
