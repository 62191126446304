import { useRef } from 'react';
import { useIntl } from 'react-intl';

import GenericSelector from '@cimpress-technology/generic-selector';

import auth from '../../auth';
import { convertToMCPProduct } from '../../services/bes';
import { ProductInputTextField } from './ProductInputTextField';
import messages from './messages';
import { ProductState as PS } from './state';

type AttributeSelectorRef = {
  getConfigurationUrl: () => Promise<string>;
};

type ProductInputPanelProps = {
  skuOrProductConfigurationText: string;
  setSkuOrProductConfigurationText: (v: string) => void;
  productVersion: string;
  setProductVersion: (v: string) => void;
  productState: PS;
  setProductState: (v: PS) => void;
  disabled: boolean;
};

type AttributeSelectorState = 'partiallyResolved' | 'fullyResolved';
type Attribute = {
  key: string;
  resolvedValue: string;
  isDisplayed: boolean;
};

const ProductInputPanel = ({
  skuOrProductConfigurationText,
  setSkuOrProductConfigurationText,
  productVersion,
  setProductVersion,
  productState,
  setProductState,
  disabled,
}: ProductInputPanelProps) => {
  const { formatMessage } = useIntl();

  const selector = useRef<AttributeSelectorRef>();

  const onGenericSelectorChange = async ({
    state,
    attributes,
  }: {
    state: AttributeSelectorState;
    attributes: Attribute[];
  }) => {
    if (state === 'fullyResolved') {
      let productConfigurationUrl = '';
      try {
        productConfigurationUrl = (await selector.current?.getConfigurationUrl()) ?? '';
      } catch {
        try {
          const selections = attributes.filter(a => a.isDisplayed);
          productConfigurationUrl = await convertToMCPProduct({
            productId: skuOrProductConfigurationText,
            version: productVersion,
            selections,
          });
        } catch {
          //ignore
        }
      }
      setProductState({ ...productState, productConfigurationUrl });
    }
  };

  return (
    <div>
      <h3>{formatMessage(messages.enterAProductSkuOrProductConfigurationUrl)}</h3>
      <ProductInputTextField
        skuOrProductConfigurationText={skuOrProductConfigurationText}
        setSkuOrProductConfigurationText={setSkuOrProductConfigurationText}
        productVersion={productVersion}
        setProductVersion={setProductVersion}
        productState={productState}
        setProductState={setProductState}
        disabled={disabled}
      />
      {productState.mcpSku || productState.productConfigurationUrl ? (
        <GenericSelector
          productId={productState.mcpSku}
          productVersion={productState.productVersion}
          configurationUrl={productState.productConfigurationUrl}
          authToken={auth.getAccessToken()}
          onChange={onGenericSelectorChange}
          ref={(instance: AttributeSelectorRef) => {
            selector.current = instance;
          }}
          disabled={disabled}
        />
      ) : undefined}
    </div>
  );
};

export default ProductInputPanel;
