import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { ComponentType, useState } from 'react';
import { useIntl } from 'react-intl';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconBagsCategory from '@cimpress-technology/react-streamline-icons/lib/IconBagsCategory';
import IconPinLocationAlt from '@cimpress-technology/react-streamline-icons/lib/IconPinLocationAlt';
import IconShop from '@cimpress-technology/react-streamline-icons/lib/IconShop';
import IconTruckConfigure from '@cimpress-technology/react-streamline-icons/lib/IconTruckConfigure';
import { colors, TabMenu } from '@cimpress/react-components';

import { isCustomQuotesProduct } from '../helpers/customquotes';
import { getDefaultPostalCodeOrEmpty } from '../helpers/postalcodes';
import { CalculatorState } from '../hooks/useCalculatorState';
import { TabWasClickedAction } from '../reducers/actionTypes';
import { getPayloadFromTabVisit } from '../reducers/actions';
import { DeliveryCalculatorState } from '../reducers/defaults';
import DeliveryConstraintsPanel from './inputPanels/DeliveryConstraintsPanel';
import LocationInputPanel from './inputPanels/LocationInputPanel';
import MerchantInputPanel from './inputPanels/MerchantInputPanel';
import ProductInputPanel from './inputPanels/ProductInputPanel';
import {
  DELIVERY_CONSTRAINTS_TAB_ID,
  LOCATION_TAB_ID,
  MERCHANT_TAB_ID,
  PRODUCT_TAB_ID,
  NavigationTabId,
  MerchantState,
  ProductState,
  LocationState,
  DeliveryConstraintsState,
} from './inputPanels/state';
import messages from './messages';

type InputTabsProps = {
  skuOrProductConfigurationText: string;
  setSkuOrProductConfigurationText: (v: string) => void;
  productVersion: string;
  setProductVersion: (v: string) => void;
  state: CalculatorState;
  setMerchantState: (v: MerchantState) => void;
  setProductState: (v: ProductState) => void;
  setLocationState: (v: LocationState) => void;
  setDeliveryConstraintsState: (v: DeliveryConstraintsState) => void;
  isLoadingItem: boolean;
  dispatch: React.Dispatch<TabWasClickedAction>;
  deliveryCalculatorState: DeliveryCalculatorState;
  timezone: string | undefined;
};

const formatTab = ({
  id,
  title,
  body,
  icon,
  shouldDisplayAlert,
}: {
  id: NavigationTabId;
  title: string;
  body: JSX.Element;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  icon: ComponentType<any> | undefined;
  shouldDisplayAlert: () => boolean;
}) => {
  const formattedTitle = (
    <div style={{ display: 'grid', gridTemplateColumns: '10fr 1fr' }}>
      <div>{title}</div>
      <div>{shouldDisplayAlert() ? <IconAlertTriangle color={colors.danger.base} /> : null}</div>
    </div>
  );
  return {
    id,
    title: formattedTitle,
    body,
    icon,
  };
};

const InputTabs = ({
  skuOrProductConfigurationText,
  setSkuOrProductConfigurationText,
  productVersion,
  setProductVersion,
  state,
  setMerchantState,
  setProductState,
  setLocationState,
  setDeliveryConstraintsState,
  isLoadingItem,
  dispatch,
  deliveryCalculatorState,
  timezone,
}: InputTabsProps) => {
  const { formatMessage } = useIntl();

  const [activeTabId, setActiveTabId] = useState<NavigationTabId>(MERCHANT_TAB_ID);

  const { postalCode, deliveryCountry } = state;
  const validatorExistsForCountry = deliveryCountry ? postcodeValidatorExistsForCountry(deliveryCountry) : false;
  const isPostalCodeValid =
    postalCode && validatorExistsForCountry ? postcodeValidator(postalCode, deliveryCountry) : true;

  const onTabClick = (_: React.FormEvent<HTMLFormElement>, tabId: string) => {
    dispatch(getPayloadFromTabVisit(activeTabId as NavigationTabId));
    setActiveTabId(tabId as NavigationTabId);
  };

  const merchantInputPanel = (
    <MerchantInputPanel
      merchantState={state}
      setMerchantState={setMerchantState}
      disabled={isLoadingItem}
      timezone={timezone}
    />
  );
  const merchantTab = formatTab({
    id: MERCHANT_TAB_ID,
    title: formatMessage(messages.merchant),
    body: merchantInputPanel,
    icon: IconShop,
    shouldDisplayAlert: () => !state.merchantId && deliveryCalculatorState.visitedTabs[MERCHANT_TAB_ID],
  });

  const productInputPanel = (
    <ProductInputPanel
      skuOrProductConfigurationText={skuOrProductConfigurationText}
      setSkuOrProductConfigurationText={setSkuOrProductConfigurationText}
      productVersion={productVersion}
      setProductVersion={setProductVersion}
      productState={state}
      setProductState={setProductState}
      disabled={isLoadingItem}
    />
  );
  const productTab = formatTab({
    id: PRODUCT_TAB_ID,
    title: formatMessage(messages.product),
    body: productInputPanel,
    icon: IconBagsCategory,
    shouldDisplayAlert: () =>
      (!state.productConfigurationUrl && deliveryCalculatorState.visitedTabs[PRODUCT_TAB_ID]) ||
      isCustomQuotesProduct({ sku: state.mcpSku }),
  });

  const locationInputPanel = (
    <LocationInputPanel
      locationState={state}
      setLocationState={setLocationState}
      disabled={isLoadingItem}
      isPostalCodeValid={isPostalCodeValid}
    />
  );

  const locationTab = formatTab({
    id: LOCATION_TAB_ID,
    title: formatMessage(messages.location),
    body: locationInputPanel,
    icon: IconPinLocationAlt,
    shouldDisplayAlert: () =>
      (!state.deliveryCountry ||
        (!state.postalCode && !getDefaultPostalCodeOrEmpty(state.deliveryCountry)) ||
        !isPostalCodeValid) &&
      !state.pickupPointUrl &&
      deliveryCalculatorState.visitedTabs[LOCATION_TAB_ID],
  });

  const deliveryConstraintsPanel = (
    <DeliveryConstraintsPanel
      deliveryConstraintsState={state}
      setDeliveryConstraintsState={setDeliveryConstraintsState}
      disabled={isLoadingItem}
    />
  );
  const deliveryConstraintsTab = formatTab({
    id: DELIVERY_CONSTRAINTS_TAB_ID,
    title: formatMessage(messages.deliveryConstraints),
    body: deliveryConstraintsPanel,
    icon: IconTruckConfigure,
    shouldDisplayAlert: () => false, // this tab is always valid
  });

  const tabs = [merchantTab, productTab, locationTab, deliveryConstraintsTab];

  return (
    <TabMenu
      activeTabId={activeTabId}
      tabs={tabs}
      onTabClick={onTabClick}
      tabStyle={{ borderTop: `1px solid ${colors.ocean.base}` }}
    />
  );
};

export default InputTabs;
