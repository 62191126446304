import fetchWithAuth from './fetchWithAuth';

export type VariableAttribute = {
  attributeKey: string;
  attributeValue: string;
};

export type ConfigurationCreation = {
  mcpSku: string;
  variables: VariableAttribute[];
  productVersion?: string;
};

export const createConfigurationUrl = async ({
  mcpSku,
  variables,
  productVersion,
}: ConfigurationCreation): Promise<string> => {
  const response = await fetchWithAuth<{ response: Response }>({
    giveSimpleResponse: false,
    method: 'POST',
    endpointUrl: process.env.REACT_APP_CONFIGURATION_URL as string,
    route: '/v1/configurations',
    body: {
      mcpSku,
      variables,
      productVersion,
    },
  });

  const locationHeader = response.response.headers.get('location');
  if (locationHeader) {
    return locationHeader;
  }
  throw new Error('Configuration creation did not have a location header');
};
