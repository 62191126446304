import find from 'lodash/find';
import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import useDeliveryOptions from '../../hooks/useDeliveryOptions';
import messages from './messages';

type DeliveryOptionSelectorProps = {
  groupId: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [x: string]: any;
};

const DeliveryOptionSelector = ({ ...props }: DeliveryOptionSelectorProps) => {
  const { formatMessage } = useIntl();
  const { groupId } = props;

  const { isLoading, data: deliveryOptions } = useDeliveryOptions({ groupId });

  if (isLoading) {
    return <Select label={formatMessage(messages.loading)} isDisabled />;
  }

  const options = deliveryOptions?.map(option => ({ label: option.name, value: option.id })) ?? [];

  return (
    <Select
      {...props}
      label={formatMessage(messages.deliveryOption)}
      options={options}
      value={props.value ? find(options, option => option.value === props.value) : ''}
    />
  );
};

export default DeliveryOptionSelector;
